import {getHistorycarList, setHistorycarList} from '@/utils/auth'

export default {
  namespaced: true,
  state: {
    history_car: getHistorycarList() ? JSON.parse(getHistorycarList()) : [],
  },
  actions: {
    // 将输入的车牌进入缓存，作为历史记录
    setCarHistory({state}, car_data) {
      let history = state.history_car
      let {name, number} = car_data
      // 清空车牌空格
      number = number.replace(/\s+/g, '')
      // 便利查找是否存在
      let history_filter = history.filter(val => {
        if (val.name === `${name}` && val.number === number) {
          return val
        }
      })
      // 如果未找到数据，则表示没有储存够
      if (history_filter.length === 0) {
        // 格式化接收的参数,避免传错
        let car = {
          name: name ? `${name}` : '',
          number: number ? number : '车牌丢失',
        }
        history.unshift(car)
        setHistorycarList(JSON.stringify(history))
      }
    },
    // 删除某个历史记录
    removeCarHistory({state}, car_data) {
      // 找到number的索引，进行删除
      let {name, number} = car_data
      let history = state.history_car
      let history_i = history.filter((val, i) => {
        if (val.name === name && val.number === number) {
          val['index'] = i
          return val
        }
      })
      history.splice(history_i[0].index, 1)
      setHistorycarList(JSON.stringify(history))
    },
  },
}
